import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

interface BeautifyLivingSpacesProps {
  data: any;
}
const BeautifyLivingSpaces: React.FC<BeautifyLivingSpacesProps> = ({
  data,
}) => {
  return (
    <>
      <section className={styles.livingsection}>
        <div className="container-1330">
          <div className={styles.livinginner}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.livingheading}>
                  <h2>{data?.title ?? ''}</h2>
                  <p>{data?.subtitle ?? ''}</p>
                </div>
              </div>
            </div>
            <div className={styles.livingcontent}>
              <div className="sellerslider beautify-living-slider">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={12}
                  navigation={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 12,
                    },
                    1300: {
                      slidesPerView: 4,
                      spaceBetween: 12,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {data?.items?.map((item: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Link
                        href={item.href}
                        className={styles.livingcard}
                        tabIndex={-1}
                      >
                        <div className={styles.livingimg}>
                          <Image
                            src={item.imgSrc}
                            alt={item.alt}
                            title={item.title}
                            loading="lazy"
                            quality={75}
                            width={445}
                            height={425}
                          />
                        </div>
                        <div className={styles.livingtext}>
                          <h4>{item.offerText}</h4>
                          <h2>{item.productName}</h2>
                          <p>{item.description}</p>
                          <span className={styles.livinglink}>
                            {item.shopNowLink}
                          </span>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={styles.mettresssection}>
        <div className="container-fluid">
          <div className={styles.mettressinner}>
            <div className="wdRow wdgx-lg-3 wdgx-2">
              <div className="wdCol-lg-6 wdCol-md-6 wdCol-sm-6 wdCol-12">
                <Link href="/mattress" className={styles.mettressimg}>
                  <Image
                    src={'/images/home-new1/new-mattress-banner.jpg?v=175'}
                    alt="Mattress"
                    title="Mattress"
                    width={895}
                    height={410}
                    loading="lazy"
                    quality={75}
                  />
                </Link>
              </div>
              <div className="wdCol-lg-6 wdCol-md-6 wdCol-sm-6 wdCol-12">
                <Link href="/home-temple" className={styles.mettressimg}>
                  <Image
                    src={'/images/home-new1/new-temple-banner.jpg?v=175'}
                    alt="Home Temple"
                    title="Home Temple"
                    width={895}
                    height={410}
                    loading="lazy"
                    quality={75}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default BeautifyLivingSpaces;
